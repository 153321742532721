<template>
  <div>
    <!-- 扫销售的二维码跳转到该页面，判断用户是否注册，未注册跳转注册页面注册并绑定销售ID -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Transition",
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo', 'isLogin']),
    sid() {
      return this.$route.query.sid;
    },
    uri() {
      return this.$route.query.uri;
    }
  },
  created() {
    this.addSalesDoctor();
  },
  methods: {
    addSalesDoctor() {
      if (this.isLogin) {
        if (this.sid) {
          location.href = decodeURIComponent(this.uri);
        } else {
          if (this.uri) {
            location.href = decodeURIComponent(this.uri);
          } else {
            this.$router.push('/myMain');
          }
        }
      } else {
        if (this.sid) {
          sessionStorage.setItem("sid", this.sid);
        }
        if (this.uri) {
          sessionStorage.setItem("uri", this.uri);
        }
        this.$router.push('/entrance');
      }
    }
  }
}
</script>